import ShipmentActionTypes from './action-types';

const initialState = {
  data: [],
  matchLocation:[],
  columns:[
  {
    name:'Modal',
    description:	'Indica o tipo de transporte utilizado no embarque, como marítimo, aéreo ou rodoviário.'
  },
  {
    name:'Processo Interno',
    description: 'Número interno de identificação do processo, usado para rastreamento e controle.'
  },
  {
    name:'Sua Referência',
    description: 'Identificação personalizada fornecida pelo cliente ou solicitante para o processo.'
  },
  {
    name:'Ano do Processo Interno',
    description: 'Ano em que o processo foi iniciado, auxiliando na organização temporal dos registros.'
  },
  {
    name:'Booking',
    description: 'Código ou número da reserva de transporte, fornecido pelo transportador.'
  },
  {
    name:'Transportador',
    description: 'Nome da empresa responsável pelo transporte da carga.'
  },
  {
    name:'Tipo de Rastreio Marítimo',
    description: 'Método utilizado para rastrear o transporte marítimo da mercadoria.'
  },
  {
    name:'Data limite de recebimento',
    description: 'Data máxima para o recebimento da carga no ponto de origem ou no porto.'
  },
  {
    name:'Origem',
    description: 'Local de onde a mercadoria será enviada.'
  },
  {
    name:'Destino',
    description: 'Local final de entrega da mercadoria.'
  },
  {
    name:'Tipo de Frete',
    description: 'Classificação do frete'
  },
  {
    name:'Total do Frete',
    description: 'Valor total do frete para o transporte da mercadoria'
  },
  {
    name:'Moeda do Frete',
    description: 'Moeda em que o valor do frete foi cotado, como USD, EUR, BRL, etc.'
  },
  {
    name:'Tempo de Free Time',
    description: 'Período em que a carga pode permanecer armazenada sem custos adicionais.'
  },
  {
    name:'Tipo de Mercadoria',
    description: 'Descrição ou categoria da mercadoria transportada.'
  },
  {
    name:'Moeda da Mercadoria',
    description: 'Moeda utilizada para o valor total da mercadoria'
  },
  {
    name:'Total da Mercadoria',
    description: 'Valor total das mercadorias embarcadas'
  },
  {
    name:'Incoterm',
    description: 'Termos internacionais de comércio que definem responsabilidades entre comprador e vendedor'
  },
  {
    name:'Operador',
    description: 'Nome ou identificação do profissional que gerencia o processo'
  },
  {
    name:'Despachante',
    description: 'Nome ou empresa responsável pelo desembaraço aduaneiro da mercadoria'
  },
  {
    name:'Analista',
    description: 'Pessoa encarregada de acompanhar o processo e garantir sua conformidade.'
  },
  {
    name:'Status do Embarque',
    description: 'Indica o estágio atual do embarque.'
  }],
  currentStep: 1,
  loading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ShipmentActionTypes.LOAD_FILE_IMPORT:
      return {
        ...state,
        data: action.payload,
      };
    case ShipmentActionTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case ShipmentActionTypes.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case ShipmentActionTypes.CANCEL_IMPORT:
      return initialState;
    case ShipmentActionTypes.LOAD_MATCH_LOCATION:
      return {
        ...state,
        matchLocation: action.payload,
      };
    case ShipmentActionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
