const ShipmentActionTypes = {
  LOAD_FILE_IMPORT: 'shipment/load_file_import',
  NEXT_STEP: 'shipment/next_step',
  PREV_STEP: 'shipment/prev_step',
  CANCEL_IMPORT: 'shipment/cancel_import',
  LOAD_MATCH_LOCATION: 'shipment/load_match_location',
  LOADING: 'shipment/loading',
  UPDATE_DATA_IMPORT: 'shipment/update_data_import',
};

export default ShipmentActionTypes;