import { Loading } from "components/shared";
import { publicRoutes } from "configs/routes.config";
import {
  LAYOUT_TYPE_AUTH,
  LAYOUT_TYPE_BLANK,
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE
} from "constants/theme.constant";
import { Suspense, lazy, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
  [LAYOUT_TYPE_AUTH]: lazy(() => import("./AuthLayout")),
};

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type);

  const { authenticated } = useAuth();
  // const location = useLocation();
  const isPublicRoute = useRoutes(publicRoutes);

  useDirection();
  useLocale();

  const AppLayout = useMemo(() => {

    //  console.log('useMemo isPublicRoute', isPublicRoute)

    if (isPublicRoute) {
      return lazy(() => import("./AuthLayout"));
    }

    if (authenticated) {
      return layouts[layoutType];
    }

    return lazy(() => import("./AuthLayout"));
  }, [layoutType, authenticated]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  );
};

export default memo(Layout);
